/* contact us modal */
.contact-us-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 6;
  display: grid;
  place-items: center;
  transition: 0.3s ease all;
  overflow-y: auto;
  overflow-x: hidden;
}


.contact-us {
  width: 635px;
  height: 790px;
  background-color: #fff;
  border-radius: 16px;
  padding: 0 74px;
  z-index: 4;
}

.contact-us-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 3;
}

.contact-us-content {
  margin-top: 65px;
}

.contact-us-title {
  font-size: 48px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 16px;
  position: relative;
}

.contact-us-title::before {
  content: "";
  position: absolute;
  left: -32px;
  top: 50%;
  background-color: #e7bb4b;
  width: 51px;
  height: 2px;
}
.contact-us-title::after {
  content: "";
  position: absolute;
  right: -32px;
  top: 50%;
  background-color: #e7bb4b;
  width: 51px;
  height: 2px;
}

.contact-us-text {
  font-weight: 200;
  font-size: 13px;
  text-align: center;
}

.contact-us-closebtn {
  position: relative;
  background-color: #fff;
  left: 320px;
  top: 20px;
  width: 31px;
  height: 31px;
  display: grid;
  place-items: center;
  border-radius: 100%;
  cursor: pointer;
  z-index: 4;
}

.contact-us-closebtn span {
  transform: scaleX(2);
  font-weight: 200;
  font-size: 14px;
}

.contact-us form {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
}

.contact-us form label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 200;
  margin-bottom: 16px;
}

.contact-us form input[type="text"],
.contact-us form input[type="tel"],
.contact-us form input[type="email"] {
  border: 1.5px solid #d9d9d9;
  border-radius: 8px;
  padding: 11px 16px;
  margin-top: 2px;
}

.contact-us form input[type="text"]:focus,
.contact-us form input[type="tel"]:focus,
.contact-us form input[type="email"]:focus {
  outline: 1.5px solid #e7bb4b;
}

.contact-us form textarea {
  border: 1.5px solid #d9d9d9;
  border-radius: 8px;
  padding: 11px 16px;
  margin-top: 2px;
  resize: none;
}

.contact-us form textarea:focus {
  outline: 1.5px solid #e7bb4b;
}

.contact-us form input[type="submit"] {
  margin-left: auto;
  margin-top: 10px;
}

.contact-us .PhoneInputCountry{
 border-radius: 8px;
 border: 1.5px solid #d9d9d9;
 padding: 0px 16px;
 height: 40px;
 margin-top: auto;

}

.error-message {
  display: none;
}

.error-message-active {
  display: block;
  font-size: 15px;
  margin-top: 10px;
  color: red;
  font-weight: normal;
  text-align: center;
}


/* info popUp */
.info-popUp{
  background-color: #fff;
  position: absolute;
  right: -120%;
  bottom: 0;
  padding: 20px 30px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  transition: .3s ease all;
}

.info-popUp-active{
  animation-name: info-popUp;
  animation-duration: 8s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-direction: alternate;
}


@keyframes info-popUp {
  0%{
    right: -100%;
  }
  10%{
    right: 0;
  }
  20%{
    right: 0;
  }
  30%{
    right: 0;
  }
  40%{
    right: 0;
  }
  50%{
    right: 0;
  }
  60%{
    right: 0;
  }
  70%{
    right: 0;
  }
  80%{
    right: 0;
  }
  90%{
    right: 0;
  }
  100%{
    right: -100%;
  }
}


.info-popUp-title{
  font-size: 15px;
  font-weight: 500;
}

.info-popUp-text{
  font-size: 14px;
}

.info-popUp span{
  font-size: 30px;
  margin-right: 20px;
}

/* responsive */
@media only screen and (max-width: 650px) {
  .contact-us {
    width: 340px;
    height: 665px;
    padding: 0 12px;
  }

  .contact-us-content{
    margin-top: 18px;
  }
  .contact-us-title {
    font-size: 24px;
  }
  .contact-us-title::before {
    left: 0;
    width: 51px;
  }
  .contact-us-title::after {
    right: 0px;
    width:51px;
  }
  .contact-us-text{
    font-size: 11px;
    font-weight: 300;
  }

  .contact-us-closebtn {
    left: 180px;
    top: 70px;
  }

  .contact-us form{
    margin-top: 20px;
  }

}
