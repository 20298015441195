header {
  padding: 25px 0;
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  transition: 0.2s ease all;
}

header .logo{
  width: 200px;
}

header .logo img{
  width: 100%;
}

.header-bg {
  background-color: #fff;
  outline: 1px solid #efefef;
}

.header-content {
  display: flex;
  align-items: center;
}

/* header btn */

.header-btn {
  margin-left: 25px;
  background-color: transparent;
  border: 0;
  width: 114px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 9px;
  border: 3px solid var(--button-primary);
  cursor: pointer;
  transition: 0.3s ease all;
  position: relative;
  padding: 0 5px;
  box-sizing: content-box;
}

.header-content a {
  font-weight: 400;
  font-size: 14px;
  color: #828282;
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  
  .header-btn {
    display: none;
  }

  .header-bg {
    padding-bottom: 5px;
  }
  header .logo{
    width: 150px;
  }
  
}
