@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap");

:root {
  --button-primary: #111;
  --button-bg: linear-gradient(180deg, #35393e 0%, #050810 74.42%);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100%;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: #e7bb4b;
}

::selection {
  color: #fff;
  background: #e7bb4b;
}

.container {
  width: 90%;
  margin: 0 auto;
  position: relative;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.primary-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 188px;
  height: 48px;
  border: 0;
  outline: 0;
  border-radius: 9px;
  background-image: var(--button-bg);
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  transition: 0.2s ease all;
  position: relative;
  overflow: hidden;
  isolation: isolate;
}

.primary-button a {
  color: #fff;
  text-decoration: none;
}

/* geçici olrak */
.contact-us-closebtn {
  display: none !important;
}

/* main title */
.main-page-title {
  font-size: 48px;
  font-weight: 500;
  letter-spacing: -0.04em;
  text-align: center;
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
}

.main-page-title::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 120%;
  background-color: #e7bb4b;
  height: 2px;
  width: 100px;
}
.main-page-title::before {
  content: "";
  position: absolute;
  right: 120%;
  top: 50%;
  background-color: #e7bb4b;
  height: 2px;
  width: 100px;
}

@media only screen and (max-width: 920px) {
  .main-page-title {
    font-size: 32px;
  }

  .main-page-title::after {
    width: 75px;
    left: 110%;
  }

  .main-page-title::before {
    width: 75px;
    right: 110%;
  }
}

.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
  transform: translateY(100px);
}

.is-visible {
  opacity: 1;
  transform: translateY(0px);
}

.modal-active {
  overflow-y: hidden;
}
