.newModal-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 6;
    display: none;
    place-items: center;
    transition: 0.3s ease all;
    overflow-y: auto;
    overflow-x: hidden;
}

.newModal {
    width: 60%;
    height: 70%;
    background-color: #FFFFFF;
    border-radius: 8px;
    position: absolute;
}

.newModal-image {
    width: 100%;
    height: 45%;
    border-radius: 8px 8px 0px 0px;
}

.newModal-image img {
    width: 100%;
    height: 100%;
    border-radius: 8px 8px 0px 0px;
}

.newModal-content {
    width: 100%;
    height: 55%;
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 80px;
}

.newModal-content h2 {
    line-height: 52px;
    font-size: 44px;
    font-weight: bold;
    color: #111111;
    margin-bottom: 32px;
    text-align: center;
}

.newModal-content h2 strong {
    color: #077eff
}

.newModal-content h3 {
    line-height: 32px;
    font-size: 24px;
    color: #111111;
    margin-bottom: 24px;
    text-align: center;
    font-weight: 300;
}

.newModal-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.newModal-btn-wrapper button {
    min-width: 175px;
    padding: 15px 20px;
    background-color: #077eff;
    border: 0px;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    font-weight: 400;
}


.newModal-close-btn {
    position: absolute;
    background-color: #111111;
    right: 20px;
    top: 20px;
    width: 31px;
    height: 31px;
    display: grid;
    place-items: center;
    border-radius: 100%;
    cursor: pointer;
    z-index: 4;
}

.newModal-close-btn span {
    transform: scaleX(2);
    font-weight: 200;
    font-size: 14px;
    color: #FFFFFF;
}